<template>  
    <v-row class="ma-0 pa-1">
      <v-col cols="12" xs="12" class="ma-0 pa-1">
          <v-card-title  class="d-flex justify-space-between">
           
            <div class="text-uppercase pl-4 mr-auto ">
              &nbsp;
            </div>
            <div class="d-flex justify-end ml-auto pr-0 pb-2">
               <o-button 
                color="primary"
                icon="mdi-sync"
                type="label-icon"
                label="Refresh"
                @on-click="refreshData()"
              />
              <div>
               <v-menu
                  left
                  bottom
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      color="lighten-3  bg-primary"                      
                      small
                      depressed
                      v-bind="attrs"
                      butt 
                      v-on="on"
                    >
                     <span class="text-capitalize">{{timeout}} seconds</span>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list style="cursor: pointer">
                    <v-list-item>
                      <v-list-item-title @click="timerSetup(2)">
                        2 seconds
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="timerSetup(5)">
                        5 seconds
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="timerSetup(10)">
                        10 seconds
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="timerSetup(30)">
                        30 seconds
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="timerSetup(60)">
                        60 seconds
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card-title>
      </v-col>
      <v-col cols="12"
        xs="12"
        sm="6"
        md="6" 
        lg="6"
        xl="6"
        class="ma-0 pa-1">
          <v-card elevation="2"> 
          <v-card-title class="d-flex justify-space-between ">
            <div class="mb-0 mr-4">
              <p class="card-title text-capitalize mb-0">
                Database Session
              </p>
            </div>  
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <apexchart
              ref="databaseSession" 
              type="line"
              height="200"
              :options="databaseSession.chartOptions"
              :series="databaseSession.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12"
        xs="12"
        sm="6"
        md="6" 
        lg="6"
        xl="6"
        class="ma-0 pa-1">
        <v-card elevation="2"> 
          <v-card-title class="d-flex justify-space-between ">
            <div class="mb-0 mr-4">
              <p class="card-title text-capitalize mb-0">
                Transaction Per  {{timeout}} Seconds
              </p>
            </div>  
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <apexchart
              ref="transactionPerSecond" 
              type="line"
              height="200"
              :options="transactionPerSecond.chartOptions"
              :series="transactionPerSecond.series"
            />
          </v-card-text>
        </v-card>        
      </v-col>
      <v-col
        cols="12" 
      >
        <base-card style="height:100%"> 
          <v-card-title class="d-flex justify-space-between ">
            <div class="mb-0 mr-4">
              <p class="card-title text-capitalize mb-0">
                Activity
              </p>
            </div>  
          </v-card-title>
          <v-card-text>
            <o-table
              ref="table" 
              type="array"
              result-type="straight"   
              :fixed-header="true"
              :no-count="true"
              :data="activity"
              :headers="[
                { text: 'PID', align: 'start', sortable: false, value: 'pid', type: 'text'},
                { text: 'User', align: 'start', sortable: false, value: 'usename', type: 'text'},
                { text: 'Client', align: 'start', sortable: false, value: 'client_addr', type: 'slot'},              
                { text: 'State', align:'start', value: 'state', type: 'text'},
                { text: 'Application', align: 'start', sortable: false, value: 'application_name', type: 'text'},                
                { text: 'Query', align:'start', value: 'query', type: 'slot'},
                { text: 'Backend Start', align: 'start', sortable: false, value: 'backend_start', type: 'date-time', width: 170},
                { text: 'Transaction Start', align: 'start', sortable: false, value: 'xact_start', type: 'date-time', width: 170},                
              ]"
            >
              <template v-slot:slot="row">
                <div
                  v-if="row.prop.key === 'query'"
                  
                >
                  <a style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" 
                    :title="row.prop.item.query"
                    v-if="row.prop.item.query.length >= 40">
                      {{row.prop.item.query.substr(0, 40)}}...
                  </a>
                  <a style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" 
                    :title="row.prop.item.query"
                    v-if="row.prop.item.query.length < 40">
                      {{row.prop.item.query}}
                  </a>
                </div>
                <div
                  v-if="row.prop.key === 'client_addr'" 
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" 
                >                  
                    {{ getIPBinding(row.prop.item.client_addr) }}
                </div>            
              </template>
            </o-table>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>  
</template>
<script> 
import RestApi from '@/services/RestApi' 
import moment from "moment";
export default {
  name: 'DashboardDatabase',
  mixins: [RestApi],
  metaInfo: {
    title: 'Performance',
  },  
  data() {
    return {
      activity: [],
      databaseSession: { chartOptions: {}, series: [] },
      transactionPerSecond: { chartOptions: {}, series: [] },
      last_commit: 0,
      last_rollback: 0,
      timeoutControl: null, 
      timeout: 10,
    }
  },
  created() {
    const date = new Date((new Date()).getTime() - 60000);    

    const defaultConfig =  _.cloneDeep(this.customChartConfig);    
    defaultConfig.series = [];
    defaultConfig.chartOptions.animations = {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000
      }
    };
    defaultConfig.chartOptions.legend = { show: false };
    defaultConfig.chartOptions.markers = {
      size: 0,
      style: 'hollow',
    } 
    defaultConfig.chartOptions.xaxis = {
      type: 'datetime', 
      tickAmount: 6
    }
    defaultConfig.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy HH:mm:ss',
      },
    }
    this.databaseSession = _.cloneDeep(defaultConfig);
    this.databaseSession.series = [
      {
        name: 'Total',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Active',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Idle',
        type: 'line',
        data: [{x: date, y: 0}]
      },      
    ];
    this.transactionPerSecond = _.cloneDeep(defaultConfig);
    this.transactionPerSecond.series = [
      {
        name: 'Transaction',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Commit',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Rollback',
        type: 'line',
        data: [{x: date, y: 0}]
      },      
    ];
    
  },
  computed: { 
  },  
  watch: {  
  }, 
  async mounted() {
    await this.refreshData();
    this.timerSetup(10);
  },
  methods: { 
    timerSetup(t) {
      this.timeout = t;      
      if (this.timeoutControl) {
        window.clearInterval(this.timeoutControl);
      }
      this.timeoutControl = window.setInterval(this.refreshData, t * 1000);
    },
    async refreshData() {
      if (this.$route.name && this.$route.name !== 'dashboard-database') {
        if (this.timeoutControl) {
          window.clearInterval(this.timeoutControl);
        }
      }
      
      const model = await this.getList(
        [ `id`,
          `(SELECT 
              COALESCE(json_agg(a), '[]'::json) AS activity
            FROM (SELECT * FROM pg_stat_activity) a) AS activity`,
          `(SELECT 
              COALESCE(json_agg(d), '[]'::json) AS stats
            FROM (
              SELECT
                SUM(COALESCE(blk_read_time, 0)) AS blk_read_time,
                SUM(COALESCE(blk_write_time, 0)) AS blk_write_time,
                SUM(COALESCE(blks_hit, 0)) AS blks_hit,
                SUM(COALESCE(blks_read, 0)) AS blks_read,
                SUM(COALESCE(checksum_failures, 0)) AS checksum_failures, 
                SUM(COALESCE(conflicts, 0)) AS conflicts,
                SUM(COALESCE(deadlocks, 0)) AS deadlocks,
                SUM(COALESCE(numbackends, 0)) AS numbackends,
                SUM(COALESCE(temp_bytes, 0)) AS temp_bytes,
                SUM(COALESCE(temp_files, 0)) AS temp_files,
                SUM(COALESCE(tup_deleted, 0)) AS tup_deleted,
                SUM(COALESCE(tup_fetched, 0)) AS tup_fetched,
                SUM(COALESCE(tup_inserted, 0)) AS tup_inserted,
                SUM(COALESCE(tup_returned, 0)) AS tup_returned,
                SUM(COALESCE(tup_updated, 0)) AS tup_updated,
                SUM(COALESCE(xact_commit, 0)) AS xact_commit,
                SUM(COALESCE(xact_rollback, 0)) AS xact_rollback   
            FROM pg_stat_database) d) AS stats`],
        `accounts`,
        [`account_id = 1`],
        [],
        `id`
      );
      if (model.length > 0) {      
        const date = new Date();
        const data = _.cloneDeep(model[0]);
        this.activity = data.activity;

        const databaseSessionSeries = this.databaseSession.series;
        databaseSessionSeries[0].data.push({x:date, y: data.activity.length});
        databaseSessionSeries[1].data.push({x:date, y: data.activity.filter((d) => d.stats == 'active').length});
        databaseSessionSeries[2].data.push({x:date, y: data.activity.filter((d) => d.stats == 'idle').length});      
        if (this.$refs.databaseSession) {          
          databaseSessionSeries[0].data = databaseSessionSeries[0].data.slice(databaseSessionSeries[0].data.length - 50, databaseSessionSeries[0].data.length);
          databaseSessionSeries[1].data = databaseSessionSeries[1].data.slice(databaseSessionSeries[1].data.length - 50, databaseSessionSeries[1].data.length);
          databaseSessionSeries[2].data = databaseSessionSeries[2].data.slice(databaseSessionSeries[2].data.length - 50, databaseSessionSeries[2].data.length);
          if (this.$refs.databaseSession) {
            this.$refs.databaseSession.updateSeries(databaseSessionSeries)        
          }
        }

        if (data.stats.length > 0) {
          const stats = data.stats[0];         
          const transactionPerSecondSeries = this.transactionPerSecond.series;   
          let commit = stats.xact_commit; 
          let rollback = stats.xact_rollback; 
          if (this.last_commit > 0) {
            commit = stats.xact_commit - this.last_commit;
            rollback = stats.xact_rollback - this.last_rollback;          
            transactionPerSecondSeries[0].data.push({x:date, y: commit + rollback});
            transactionPerSecondSeries[1].data.push({x:date, y: commit});
            transactionPerSecondSeries[2].data.push({x:date, y: rollback});

            transactionPerSecondSeries[0].data = transactionPerSecondSeries[0].data.slice(transactionPerSecondSeries[0].data.length - 50, transactionPerSecondSeries[0].data.length);
            transactionPerSecondSeries[1].data = transactionPerSecondSeries[1].data.slice(transactionPerSecondSeries[1].data.length - 50, transactionPerSecondSeries[1].data.length);
            transactionPerSecondSeries[2].data = transactionPerSecondSeries[2].data.slice(transactionPerSecondSeries[2].data.length - 50, transactionPerSecondSeries[2].data.length);
            if (this.$refs.transactionPerSecond) {
              this.$refs.transactionPerSecond.updateSeries(transactionPerSecondSeries)        
            }
          }
          this.last_commit = stats.xact_commit; 
          this.last_rollback = stats.xact_rollback; 
        }      
      }  
    },
    getIPBinding(ip) {
      let data = '';
      if (!ip) {
        return 'Unknown'
      }
      switch (ip) {
        case '172.31.3.15': data = `crons(${ip})`; break;
        case '12.0.7.148': data = `bidder(${ip})`; break;
        case '10.20.0.70': data = `replication(${ip})`; break;
        case '172.31.35.174': data = `transcoding(${ip})`; break;
        case '172.31.41.68': data = `transcoding(${ip})`; break;
        case '172.31.38.135': data = `api(${ip})`; break;
        case '172.31.23.10': data = `api(${ip})`; break;
        default: data = `Unknown(${ip})`
      }
      return data;
    }
  }
}
</script>
<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>