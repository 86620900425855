<template>
  <DashboardDatabase dialog-name="Database" />
</template>
<script>
import DashboardDatabase from '@/views/app/dashboard/Database'

export default {
  name: 'DashboardDatabaseWrapper',
  components: {
    DashboardDatabase
  }
}
</script>
